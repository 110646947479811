import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";

export const Share = React.forwardRef((props: any, ref: any) => {
  return (
    <div>
      {props.twitter && (
        <TwitterShareButton ref={ref} url={props.twitter}>
          twitter
        </TwitterShareButton>
      )}
      {props.facebook && (
        <FacebookShareButton ref={ref} url={props.facebook}>
          facebook
        </FacebookShareButton>
      )}
      {props.download && (
        <a download href={props.download} ref={ref}>
          download
        </a>
      )}
    </div>
  );
});
