import { graphql, Link } from "gatsby";
import React, { useEffect } from "react";
import { usePageContext } from "../components/Context/pageContext";
import { Layout } from "../components/Layout/layout";
import { Button } from "../components/Parts/button";
import { Copyright } from "../components/Parts/copyright";
import { Logo } from "../components/Parts/logo";
import { Title } from "../components/Parts/title";
import SEO from "../components/seo";
import { Share } from "../components/Share/share";
import "../styles/globals.scss";
import * as styles from "../styles/index.module.scss";
import { Location } from "./message";

const BravoPage: React.FC<Location & any> = ({ location, data: { bravo } }) => {
  const { lang } = usePageContext();
  const ref: any = React.createRef();

  useEffect(() => {
    if (location.state) ref.current.click();
  }, []);

  return (
    <>
      <Layout>
        <SEO
          title="Bravo"
          links={[
            {
              rel: "preconnect",
              href: `https://res.cloudinary.com`,
            },
            location.state?.download
              ? {
                  rel: "preload",
                  href: location.state.download,
                }
              : {},
          ]}
        />
        {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div> */}
        <div className={styles.pageWrapper}>
          <div className={styles.whiteBox}>
            <div className={styles.contentWrapper}>
              <div className={styles.bravoWrapper}>
                <div className={styles.logo}>
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
                <div className={styles.bravoTitleWrapper}>
                  <Title content={bravo.content[lang]} />
                </div>
                {/* <div className={styles.buttonWrapper}> */}
                {/* <div className={styles.button}>C'est parti !</div> */}
                <Button
                  content={bravo.button[lang]}
                  destination="/visual"
                  clickable={true}
                />
              </div>
              <div className={styles.bravoSubTitle}>{bravo.subtitle[lang]}</div>
              <Button
                content={bravo.subscribeButton?.[lang]}
                destination={bravo.subscribeLink?.[lang]}
                clickable={true}
                className={styles.marginBottom}
              />
              <Button
                content={bravo.toursButton?.[lang]}
                destination={bravo.toursLink?.[lang]}
                clickable={true}
              />
              <div className={styles.copyrightWrapper}>
                <Copyright />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.share}>
          <Share {...location.state} ref={ref} />
        </div>
      </Layout>
    </>
  );
};

export default BravoPage;

export const query = graphql`
  query bravo {
    bravo: sanityApp {
      button: bravoButton {
        en
        fr
      }
      content: bravoContent {
        en
        fr
      }
      subtitle: bravoSubtitle {
        en
        fr
      }
      subscribeButton {
        en
        fr
      }
      subscribeLink {
        en
        fr
      }
      toursButton {
        fr
        en
      }
      toursLink {
        en
        fr
      }
    }
  }
`;
